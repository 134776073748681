<template>
    <div class="flex w-100">
        <div class="flex w-100 center-xs middle-xs">
            <div class="flex mt-30 col middle-xs" :class="isMobileView ? 'w-90' : 'w-40'">
                <div class="flex head pl-10 pr-10 mb-30">ADD DEVICE</div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Device Name" v-model="data.name" @focusout="data.isInvalidName = data.name == ''">
                    <div  v-if="data.isInvalidName" class="error mb-5 p-5">Device name is required</div>
                </div>
                <div class="flex w-100 mb-5">
                    <div class="mt-10">Device type: </div>
                    <div class="ml-10 mt-5 pl-10 pr-10 pt-5 pb-5 btn" :class="data.type == 'STB' ? 'buttonC' : 'button'" @click="data.type = 'STB'">STB</div>
                    <div class="ml-10 mt-5 pl-10 pr-10 pt-5 pb-5 btn" :class="data.type == 'browser' ? 'buttonC' : 'button'" @click="data.type = 'browser'">Browser</div>
                </div>
                <div  v-if="data.isInvalidType" class="error mb-5 p-5">Device type is required</div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Brand Name" v-model="data.brand">
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15  pl-20 inputEle" type="text" placeholder="Model" v-model="data.model">
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15  pl-20 inputEle" type="text" placeholder="Resolution" v-model="data.resolution">
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <input class="w-100 pt-15 pb-15  pl-20 inputEle" type="number" placeholder="RAM" v-model="data.ram" @focusout="data.isInvalidRam = data.ram < 0">
                    <div  v-if="data.isInvalidRam" class="flex error mt-5 p-5">RAM must be a positive integer</div>
                </div>
                <div class="flex w-100 mt-5 mb-5">
                    <van-checkbox v-model="data.isShared" checked-color="#0fab61" icon-size="30px">Shared Device</van-checkbox>
                </div>
                <div class="flex w-100">
                    <image-uploader @image-loaded="onImageLoaded"></image-uploader>
                    <div  v-if="data.isInvalidImage" class="error mb-5 p-5">Image upload failed, Please upload again !</div>
                </div>
                <div  v-if="data.isCreationFailed" class="error mb-5 p-5">Device creation failed, Please try agian !</div>
                <div class="flex w-100 mt-30">
                    <div class="w-40 pt-15 pb-15 buttonC" :class="isMobileView ? 'pl-10 pr-10' : 'pl-15 pr-15'" @click="navigateBack()">Back</div>
                    <div class="w-40 pt-15 pb-15 buttonC buttonR" :class="isMobileView ? 'pl-10 pr-10' : 'pl-15 pr-15'" @click="addDevice()">Add Device</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { isMobile } from "@/lib/utils"
import { computed, reactive } from 'vue'
import router from "@/lib/router.js"
import { post } from "@/lib/api.js"
import ImageUploader from "../../components/ImageUploader.vue"

//const name = "Register"
const data = reactive({
    isInvalidName: false,
    isInvalidType: false,
    isInvalidRam: false,
    isInvalidImage: false,
    isCreationFailed: false,
    name: '',
    type: '',
    brand: '',
    model: '',
    resolution: '',
    ram: '',
    isShared: false,
    image: '',
    isInvalidData: function() {
        this.isInvalidType = this.type == ''
        this.isInvalidName = this.name == ''
        this.isInvalidRam = this.ram < 0 || this.ram == ''
        return (this.isInvalidType || this.isInvalidName || this.isInvalidRam || this.isInvalidImage)
    }
})

let imageObj;
function onImageLoaded(image){
    imageObj = image;
}

const isMobileView = computed(() => {
    return isMobile()
})
function navigateBack() {
    router.push({path: '/devices'})
}
async function addDevice() {
    if (data.isInvalidData()) {
        return
    }
    try {
        //upload image
        const imageRes = await post('users/me/images/device', true, imageObj)
        data.image =  imageRes.data.imagePath

        const device = await post('users/me/devices', true, data);
        if(device.status == "success") {
            router.push({path: '/devices'})
        }
    } catch (error) {
        if(['imageUploadFailed','invalidImageType'].includes(error.response.data.message)) {
            data.isInvalidImage = true
        } else {
            data.isCreationFailed = true
        }
        console.error('error adding device:', error);
    }
}
</script>
<style lang="scss" scoped>
.buttonC {
    border-radius: 10px;
}
.button {
    border: 1px solid gray;
    border-radius: 10px;
}
.head {
    font-family: Bold;
    font-size: 20px;
    color: #000;
}
.buttonR {
    margin-left: 20%;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
